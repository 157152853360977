import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center justify-between pb-4 border-b" }
const _hoisted_3 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_4 = { class: "mt-8 grid grid-col-1 gap-4" }
const _hoisted_5 = { class: "mt-1 font-medium leading-tight text-gray-900" }
const _hoisted_6 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseCard, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('platform.role.roles')), 1),
              _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                to: "roles/new",
                color: "primary",
                "left-icon": "add",
                class: "ml-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.role.new')), 1)
                ]),
                _: 1
              })), [
                [_directive_allow, 'role:create']
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: role.id,
                  class: "flex items-center justify-between px-5 py-4 border rounded-lg"
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(role.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (role.assignment_count)
                      ? (_openBlock(), _createBlock(_component_ButtonLink, {
                          key: 0,
                          to: `roles/${role.id}/users`,
                          color: "ghost",
                          class: "mr-4",
                          "right-icon": "chevron-right"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.role.users', [role.assignment_count])), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_BasePopover, { position: "left" }, {
                      button: _withCtx(() => [
                        _createVNode(_component_SpecialityButton)
                      ]),
                      menu: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(_component_PopoverLink, {
                          to: 'roles/' + role.id + '/users'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.user.view')), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])), [
                          [_directive_allow, 'user:read']
                        ]),
                        _withDirectives((_openBlock(), _createBlock(_component_PopoverLink, {
                          to: 'roles/' + role.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.role.edit')), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])), [
                          [_directive_allow, 'role:update']
                        ]),
                        _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          onClick: ($event: any) => (_ctx.deleteRole(role.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.role.delete')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'role:delete']
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}